
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col :span="24">
        <el-form :inline="true" label-width="120px" @submit.native.prevent>
          <el-form-item label="收单行:">
            <el-select
              v-model="queryParams.acquirer"
              filterable
              placeholder="请选择收单行"
              @change="handleQuery"
            >
              <el-option
                v-for="item in acquirerOptions"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="选择监管:">
            <el-select
              v-model="queryParams.PayType"
              filterable
              placeholder="请选择监管"
              @change="handleQuery"
            >
              <el-option
                v-for="item in payTypeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="交款单号:">
            <el-input
              v-model="queryParams.crmTransNo"
              placeholder="请输入交款单号"
            />
          </el-form-item>

          <el-form-item label="客户姓名:">
            <el-input
              v-model="queryParams.customName"
              placeholder="请输入客户姓名"
            />
          </el-form-item>
          <el-form-item label="银行流水:">
            <el-input
              v-model="queryParams.bankFlowNo"
              placeholder="请输入银行流水号"
            />
          </el-form-item>
          <el-form-item label="设备编号:">
            <el-input
              v-model="queryParams.terminalCode"
              placeholder="请输入设备编号"
            />
          </el-form-item>
          <el-form-item label="公司名称" prop="orgName">
            <el-select
              @change="handleQuery"
              v-model="queryParams.OrganName"
              filterable
              placeholder="请选择公司名称"
              style="width: 100%"
            >
              <el-option
                v-for="item in OrganOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="支付金额:">
            <el-input
              v-model="queryParams.payAmount"
              placeholder="请输入支付金额"
            />
          </el-form-item>
          <el-form-item label="业务类型:">
            <el-select
              v-model="queryParams.busType"
              filterable
              placeholder="请选择业务类型"
              @change="handleQuery"
            >
              <el-option
                v-for="item in BusTypeOption"
                :key="item.id"
                :label="item.code + ' | ' + item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="queryParams.payStatus"
              filterable
              placeholder="请选择支付状态"
              @change="handleQuery"
            >
              <el-option
                v-for="item in payStatusOption"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期:">
            <el-date-picker
              v-model="queryParams.beginDate"
              :editable="false"
              :clearable="false"
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
              @change="handleQuery"
            />
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="queryParams.endDate"
              :editable="false"
              :clearable="false"
              type="date"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-row type="flex" class="mb8">
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon=" el-icon-document-copy"
          @click="exportExcel"
        />
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="bustransrecord"
        id="bustransrecord"
        v-loading="loading"
        :data="dataBusTransRecord"
        row-key="id"
        show-summary
        :summary-method="getSummaries"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />

        <el-table-column
          sortable
          prop="transDate"
          align="center"
          :show-overflow-tooltip="true"
          label="交易时间"
        />
        <el-table-column
          sortable
          prop="organName"
          align="center"
          :show-overflow-tooltip="true"
          label="公司名称"
        />
        <el-table-column
          sortable
          prop="projectName"
          align="center"
          :show-overflow-tooltip="true"
          label="项目名称"
        />

        <el-table-column
          sortable
          prop="payModeName"
          align="center"
          :show-overflow-tooltip="true"
          label="支付方式"
        />

        <el-table-column
          sortable
          prop="terminalCode"
          align="center"
          :show-overflow-tooltip="true"
          label="设备编号"
        />
        <el-table-column
          sortable
          prop="acquirerName"
          align="center"
          :show-overflow-tooltip="true"
          label="收单机构"
        />
        <el-table-column
          sortable
          prop="busTypeName"
          align="center"
          :show-overflow-tooltip="true"
          label="业务类型"
        />

        <!-- <el-table-column
          sortable
          prop="bankAccount"
          align="center"
          :show-overflow-tooltip="true"
          label="收款账号"
        /> -->
        <el-table-column
          sortable
          prop="busTypeName"
          align="center"
          :show-overflow-tooltip="true"
          label="交易类型"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.payType == '1'"> 非监管 </span>
            <span v-if="scope.row.payType == '0'"> 监管</span>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="payCount"
          align="center"
          :show-overflow-tooltip="true"
          label="笔数"
        />
        <el-table-column
          sortable
          prop="payAmount"
          align="right"
          :show-overflow-tooltip="true"
          label="支付金额"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payAmount | formatCurrency }}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>
  </div>
</template>
<script>
import {
  groupQuery,
  createBusTransRecord,
  updateBusTransRecord,
  deleteBusTransRecord,
  exportGroupExcel,
} from "@/api/Estate/bustransrecord";
import { getAllBusType } from "@/api/Estate/bustype";
import { getAllEstateOrgan } from "@/api/Estate/estateorgan";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { formatAmount } from "@/utils/filter";
import { formatDate } from "@/utils/index";
import { getAllAcquirer } from "@/api/Estate/acquirer"; //收单行注入接口
export default {
  name: "bustransrecord",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      BusTypeOption: [],
      OrganOptions: [],
      acquirerOptions: [],
      payTypeOptions: [
        { code: "", name: "全部" },
        { code: "0", name: "监管" },
        { code: "1", name: "非监管" },
      ],
      // 合计条数
      total: 0,
      gtotal: 0,
      gcount: 0,
      // 遮罩层
      loading: true,

      // 查询参数
      queryParams: {
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        payStatus: "1",
        orderby: "TransDate",
        sort: "descending",
        beginDate: formatDate(-7),
        endDate: formatDate(),
      },
      payStatusOption: [
        { code: "", name: "全部" },
        { code: "0", name: "未知" },
        { code: "1", name: "已支付" },
        { code: "2", name: "已退款" },
      ],
      // 业务数据列表
      dataBusTransRecord: [],
      // 表单校验
      rules: {
        crmTransNo: [
          { required: true, message: "交款单号编号不能为空", trigger: "blur" },
        ],
        busType: [{ required: true, message: "业务类型", trigger: "blur" }],
        customName: [
          { required: true, message: "客户姓名编号不能为空", trigger: "blur" },
        ],
        payAmount: [
          { required: true, message: "支付金额编号不能为空", trigger: "blur" },
        ],
        transTime: [
          { required: true, message: "交易时间编号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    getAllBusType(true).then((response) => {
      this.BusTypeOption = response.data;
    });
    this.getList();
    this.getOrgan();
    getAllAcquirer(true).then((response) => {
      this.acquirerOptions = response.data;
      this.acquirerOptions.unshift({ id: "", code: "", name: "全部" });
    });
  },
  methods: {
    /** 查询业务数据列表 */
    getList() {
      this.loading = true;
      groupQuery(this.queryParams).then((response) => {
        this.dataBusTransRecord = response.data.dataSource;

        this.total = response.data.totalCount;
        this.gtotal = response.data.totalAmount;
        this.gcount = response.data.pCount;
        this.loading = false;
      });
    },
    getOrgan() {
      this.loading = true;

      getAllEstateOrgan(true).then((response) => {
        this.OrganOptions = response.data;
        this.OrganOptions.unshift({ name: "全部", code: "" });
        this.loading = false;
      });
    },
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams = {
        crmTransNo: "",
        customName: "",
        bankFlowNo: "",
        terminalCode: "",
        orgName: "",
        payAmount: "",
        beginDate: formatDate(-7),
        endDate: formatDate(),
      };
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "添加业务数据";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "修改业务数据";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.bustransrecordName + '"的业务数据?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteBusTransRecord(row.id).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            updateBusTransRecord(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          } else {
            createBusTransRecord(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        bustransrecordName: undefined,
        bustransrecordNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));

        if (
          !values.every((value) => isNaN(value)) &&
          column.property === "payAmount"
        ) {
          sums[index] = Number.parseFloat(this.gtotal).toFixed(2);
        } else if (
          !values.every((value) => isNaN(value)) &&
          column.property === "payCount"
        ) {
          sums[index] = this.gcount;
        } else {
          sums[index] = "/";
        }
      });
      //  sums[0] = "合计";
      return sums;
    },
    calaDate(AddDayCount) {
      let aData = new Date();
      aData.setDate(aData.getDate() + AddDayCount);
      return (
        aData.getFullYear() +
        "-" +
        (aData.getMonth() + 1) +
        "-" +
        aData.getDate()
      );
    },
    async exportExcel() {
      /* 从表生成工作簿对象 */

      this.loading = true;
      exportGroupExcel(this.queryParams).then((response) => {
        console.log("================", response);
        this.loading = false;
        let data = response.data;

        //展示的顺序，把data中对象的属性按照你想要的顺序排放就可以了，我这里把id移到了第一列展示
        const header = [
          "transDate",
          "organName",
          "projectName",
          "payModeName",
          "terminalCode",
          "acquirerName",
          "busTypeName",
          "bankAccount",
          "payCount",
          "payAmount",
        ];
        data = data.map((item) => {
          let newitem = {};
          for (var key in item) {
            if (header.indexOf(key) > -1) newitem[key] = item[key];
          }
          return newitem;
        });

        //展示的名称
        const headerDisplay = {
          transDate: "交易时间",
          organName: "公司名称",
          projectName: "项目名称",
          payModeName: "支付方式",
          terminalCode: "设备编号",
          acquirerName: "收单机构",
          busTypeName: "交易类型",
          bankAccount: "收款账号",
          payCount: "笔数",
          payAmount: "支付金额",
        };

        //将表头放到原始数据里面去，要保证表头在数组的最前面
        const newData = [headerDisplay, ...data];

        this.json2Excel(newData, {
          header: header,
          skipHeader: true,
        });
        //加了一句skipHeader:true，这样就会忽略原来的表头
        // var wb = XLSX.utils.json_to_sheet(newData, {
        //   header: header,
        //   skipHeader: true,
        // });

        // // var wb = XLSX.utils.table_to_book(
        // //   document.querySelector("#bustransrecord"),{raw: true }
        // // );

        // /* 获取二进制字符串作为输出 */
        // var wbout = XLSX.write(wb, {
        //   bookType: "xlsx",
        //   bookSST: true,
        //   type: "array",
        // });
        // try {
        //   FileSaver.saveAs(
        //     //Blob 对象表示一个不可变、原始数据的类文件对象。
        //     //Blob 表示的不一定是JavaScript原生格式的数据。
        //     //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        //     //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        //     new Blob([wbout], { type: "application/octet-stream" }),
        //     //设置导出文件名称
        //     "sheel.xlsx"
        //   );
        // } catch (e) {
        //   if (typeof console !== "undefined") console.log(e, wbout);
        // }
        // return wbout;
      });
    },
    json2Excel(dataSource, opts) {
      var wopts = {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
      };
      var workBook = {
        SheetNames: ["Sheet1"],
        Sheets: {},
        Props: {},
      };
      //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
      //2、将数据放入对象workBook的Sheets中等待输出
      workBook.Sheets["Sheet1"] = XLSX.utils.json_to_sheet(dataSource, opts);

      //3、XLSX.write() 开始编写Excel表格
      //4、changeData() 将数据处理成需要输出的格式
      this.saveAs(
        new Blob([this.changeData(XLSX.write(workBook, wopts))], {
          type: "application/octet-stream",
        })
      );
    },
    changeData(s) {
      //如果存在ArrayBuffer对象(es6) 最好采用该对象
      if (typeof ArrayBuffer !== "undefined") {
        //1、创建一个字节长度为s.length的内存区域
        var buf = new ArrayBuffer(s.length);

        //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
        var view = new Uint8Array(buf);

        //3、返回指定位置的字符的Unicode编码
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      } else {
        var buf = new Array(s.length);
        for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
    },
    saveAs(obj, fileName) {
      //当然可以自定义简单的下载文件实现方式
      var tmpa = document.createElement("a");
      tmpa.download = fileName
        ? fileName + ".xlsx"
        : new Date().getTime() + ".xlsx";
      tmpa.href = URL.createObjectURL(obj); //绑定a标签
      tmpa.click(); //模拟点击实现下载

      setTimeout(function () {
        //延时释放
        URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
      }, 100);
    },
  },
  filters: {
    formatCurrency(data) {
      return formatAmount(data);
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs["bustransrecord"].doLayout();
    });
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
