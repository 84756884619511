import request from '@/utils/request'
export function queryEstateProject(data) {
  return request({
	url: 'estateproject/query',
	method: 'post',
	data
  })
}
export function getEstateProject(id) {
  return request({
	url: 'estateproject/get?id=' + id,
	method: 'get'
  })
}
export function getAllEstateProject(enable) {
  return request({
	url: 'estateproject/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createEstateProject(data) {
  return request({
	url: 'estateproject/create',
	method: 'post',
	data
  })
}
export function updateEstateProject(data) {
  return request({
	url: 'estateproject/update',
	method: 'post',
	data
  })
}
export function deleteEstateProject(id) {
  return request({
	url: 'estateproject/delete?id=' + id,
	method: 'get'
  })
}
export default { queryEstateProject, getEstateProject, getAllEstateProject, createEstateProject, updateEstateProject, deleteEstateProject }
