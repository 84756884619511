
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="票据抬头:">
            <el-input
              v-model="queryParams.Title"
              placeholder="请输入票据抬头"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>

          <el-form-item label="项目名称:">
            <el-input
              v-model="queryParams.ProjName"
              placeholder="请输入项目名称"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" class="mb8">
      <!-- <el-button v-permission="['PRIV_TICKETPARAM_CREATE']" type="success" icon="el-icon-plus" size="mini" @click="handleCreate">新增票据参数设置</el-button> -->
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="ticketparam"
        v-loading="loading"
        :data="dataTicketParam"
        row-key="id"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />

        <el-table-column
          sortable
          prop="title"
          align="left"
          :show-overflow-tooltip="true"
          label="票据抬头"
        />
        <el-table-column
          sortable
          prop="projName"
          align="left"
          :show-overflow-tooltip="true"
          label="项目名称"
        />
        <el-table-column
          sortable
          prop="logoUrl"
          align="center"
          :show-overflow-tooltip="true"
          label="Log图标"
        >
          <template slot-scope="scope">
            <a
              v-show="scope.row.logoUrl"
              class="downFile"
              target="_blank"
              :href="scope.row.logoUrl"
            >
              <el-image
                style="width: 200px; height: 50px"
                :src="scope.row.logoUrl"
              ></el-image
            ></a>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="stampUrl"
          align="center"
          :show-overflow-tooltip="true"
          label="印章图标"
        >
          <template slot-scope="scope">
            <a
              v-show="scope.row.stampUrl"
              class="downFile"
              target="_blank"
              :href="scope.row.stampUrl"
            >
              <el-image
                style="width: 76px; height: 50px"
                :src="scope.row.stampUrl"
              ></el-image
            ></a>
          </template>
        </el-table-column>

        <el-table-column
          sortable
          prop="remark"
          align="center"
          :show-overflow-tooltip="true"
          label="备注"
        />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-permission="['PRIV_TICKETPARAM_UPDATE']"
                type="info"
                size="mini"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
              />
              <el-button
                v-permission="['PRIV_TICKETPARAM_DELETE']"
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
              />
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="项目名称" maxlength="100" prop="projGUID">
              <el-input v-model="form.projName" placeholder="项目名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="票据抬头" maxlength="255" prop="title">
              <el-input v-model="form.title" placeholder="请输入票据抬头" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Log图标" maxlength="255" prop="logoUrl">
              <el-upload
                class="avatar-uploader"
                :action="auImmUploadUrl"
                :headers="headers"
                :on-change="fileChange"
                accept=".jpg,.jpeg,.png"
                :before-upload="beforeAvatarUpload"
                :on-success="handleLogoSuccess"
                :show-file-list="false"
                :on-exceed="onExceed"
              >
                <img
                  v-if="form.logoUrl"
                  fits="cover"
                  :src="form.logoUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="印章图标" maxlength="255" prop="stampUrl">
              <el-upload
                class="avatar-uploader"
                :action="auImmUploadUrl"
                :headers="headers"
                :on-change="fileChange"
                accept=".jpg,.jpeg,.png"
                :before-upload="beforeAvatarUpload"
                :on-success="handleStampSuccess"
                :show-file-list="false"
                :on-exceed="onExceed"
              >
                <img
                  v-if="form.stampUrl"
                  fits="cover"
                  :src="form.stampUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注" maxlength="255" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryTicketParam,
  createTicketParam,
  updateTicketParam,
  deleteTicketParam,
} from "@/api/Estate/ticketparam";
import { getToken } from "@/utils/auth";
export default {
  name: "ticketparam",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      headers: { SYSTOKEN: getToken() },
      // 表格高度
      tableHeight: window.innerHeight,
      auImmUploadUrl: process.env.VUE_APP_BASE_API + "/TicketParam/ImgUpload",
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
      },
      // 票据参数设置列表
      dataTicketParam: [],
      // 表单校验
      rules: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询票据参数设置列表 */
    getList() {
      this.loading = true;
      queryTicketParam(this.queryParams).then((response) => {
        this.dataTicketParam = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams.queryText = "";
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "添加票据参数设置";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();

      this.form = row;
      this.form.ProjGUID = row.projCode;
      this.open = true;
      this.title = "修改票据参数设置";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.title + '"的票据参数设置?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteTicketParam(row.projCode).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          //   if (this.form.id !== undefined) {
          //     updateTicketParam(this.form).then((response) => {
          //       if (response.statusCode === 200) {
          //         this.$message({
          //           message: "修改成功",
          //           type: "success",
          //         });
          //         this.open = false;
          //         this.getList();
          //       }
          //     });
          //   } else {
          createTicketParam(this.form).then((response) => {
            if (response.statusCode === 200) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.open = false;
              this.getList();
            }
          });
          // }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        ticketparamName: undefined,
        ticketparamNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
    beforeAvatarUpload(file) {
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error("上传头像图片大小不能超过 4MB!");
      }
      return isLt4M;
    },
    // 图片上传成功
    handleStampSuccess(response, file) {
      if (response.statusCode === 200) {
        this.form.stampSourceName = response.data.soureFileName;
        this.form.stampUrl = response.data.webUrl;
        this.$message({
          message: "印章图片上传成功",
          type: "success",
        });
      }
    },
    handleLogoSuccess(response, file) {
      if (response.statusCode === 200) {
        this.form.logoSourceName = response.data.soureFileName;
        this.form.logoUrl = response.data.webUrl;
        this.$message({
          message: "Logo图片上传成功",
          type: "success",
        });
      }
    },
    fileChange(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },

    onExceed(file) {
      this.fileList = [
        {
          name: file[0].name,
        },
      ];
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.cu-avatar {
  position: absolute;
  width: 20%;
  right: 35px;
  top: 70px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-span {
  margin-top: 10px;
  width: 178px;
  text-align: center;
  color: #409eff;
}
.el-upload__tip {
  margin-top: 10px;
  width: 178px;
  text-align: center;
  color: #ff0000;
}
</style>

