import request from '@/utils/request'

export function GetAllRelationTree() {
  return request({
    url: '/Authorize/GetRelationTree',
    method: 'get'
  })
}

export function GetRelationTree(userID) {
  return request({
    url: '/Authorize/GetRelationTree?userID=' + userID,
    method: 'get'
  })
}

export function UpdateUserRelation(data) {
  return request({
    url: '/Authorize/UpdateUserRelation',
    method: 'post',
    data
  })
}
export function getRelationUsers(objectID,objectType) {
  return request({
    url: '/Authorize/getOrganUser?objectID=' + objectID+"&objectType="+objectType,
    method: 'get'
  })
}
// 添加机构用户
export function createRelationUsers(data) {
  return request({
    url: '/Authorize/create',
    method: 'post',
    data
  })
}
// 删除机构用户
export function deleteRelationUsers(data) {
  return request({
    url: '/Authorize/delete',
    method: 'post',
    data
  })
}
// 查询机构未添加用户列表
export function getExcludeUsers(objectID,objectType) {
  return request({
    url: '/Authorize/getExcludeUsers?objectID=' + objectID+"&objectType"+objectType,
    method: 'get'
  })
}
export default { GetRelationTree, UpdateUserRelation, GetAllRelationTree,getExcludeUsers,deleteRelationUsers ,createRelationUsers,getRelationUsers}
