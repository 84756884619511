import request from '@/utils/request'
export function queryDeviceBankTerminal(data) {
  return request({
	url: 'devicebankterminal/query',
	method: 'post',
	data
  })
}
export function getDeviceBankTerminal(id) {
  return request({
	url: 'devicebankterminal/get?id=' + id,
	method: 'get'
  })
}
export function getAllDeviceBankTerminal(enable) {
  return request({
	url: 'devicebankterminal/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createDeviceBankTerminal(data) {
  return request({
	url: 'devicebankterminal/create',
	method: 'post',
	data
  })
}
export function updateDeviceBankTerminal(data) {
  return request({
	url: 'devicebankterminal/update',
	method: 'post',
	data
  })
}
export function deleteDeviceBankTerminal(id) {
  return request({
	url: 'devicebankterminal/delete?id=' + id,
	method: 'get'
  })
}
export default { queryDeviceBankTerminal, getDeviceBankTerminal, getAllDeviceBankTerminal, createDeviceBankTerminal, updateDeviceBankTerminal, deleteDeviceBankTerminal }
