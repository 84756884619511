import request from '@/utils/request'
export function queryAuthUser(data) {
  return request({
	url: 'authuser/query',
	method: 'post',
	data
  })
}
export function getAuthUser(id) {
  return request({
	url: 'authuser/get?id=' + id,
	method: 'get'
  })
}
export function getAllAuthUser(enable) {
  return request({
	url: 'authuser/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createAuthUser(data) {
  return request({
	url: 'authuser/create',
	method: 'post',
	data
  })
}
export function updateAuthUser(data) {
  return request({
	url: 'authuser/update',
	method: 'post',
	data
  })
}
export function deleteAuthUser(id) {
  return request({
	url: 'authuser/delete?id=' + id,
	method: 'get'
  })
}
export default { queryAuthUser, getAuthUser, getAllAuthUser, createAuthUser, updateAuthUser, deleteAuthUser }
