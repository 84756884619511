import request from '@/utils/request'
export function queryTerminalMode(data) {
  return request({
	url: 'terminalmode/query',
	method: 'post',
	data
  })
}
export function getTerminalMode(id) {
  return request({
	url: 'terminalmode/get?id=' + id,
	method: 'get'
  })
}
export function getAllTerminalMode(enable) {
  return request({
	url: 'terminalmode/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createTerminalMode(data) {
  return request({
	url: 'terminalmode/create',
	method: 'post',
	data
  })
}
export function updateTerminalMode(data) {
  return request({
	url: 'terminalmode/update',
	method: 'post',
	data
  })
}
export function deleteTerminalMode(id) {
  return request({
	url: 'terminalmode/delete?id=' + id,
	method: 'get'
  })
}
export default { queryTerminalMode, getTerminalMode, getAllTerminalMode, createTerminalMode, updateTerminalMode, deleteTerminalMode }
