import request from '@/utils/request'
export function queryAcquirer(data) {
  return request({
	url: 'acquirer/query',
	method: 'post',
	data
  })
}
export function getAcquirer(id) {
  return request({
	url: 'acquirer/get?id=' + id,
	method: 'get'
  })
}
export function getAllAcquirer(enable) {
  return request({
	url: 'acquirer/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createAcquirer(data) {
  return request({
	url: 'acquirer/create',
	method: 'post',
	data
  })
}
export function updateAcquirer(data) {
  return request({
	url: 'acquirer/update',
	method: 'post',
	data
  })
}
export function deleteAcquirer(id) {
  return request({
	url: 'acquirer/delete?code=' + id,
	method: 'get'
  })
}
export default { queryAcquirer, getAcquirer, getAllAcquirer, createAcquirer, updateAcquirer, deleteAcquirer }
