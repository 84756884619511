import request from '@/utils/request'
export function queryAppSoft(data) {
  return request({
	url: 'appsoft/query',
	method: 'post',
	data
  })
}
export function getAppSoft(id) {
  return request({
	url: 'appsoft/get?id=' + id,
	method: 'get'
  })
}
export function getAllAppSoft(enable) {
  return request({
	url: 'appsoft/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createAppSoft(data) {
  return request({
	url: 'appsoft/create',
	method: 'post',
	data
  })
}
export function updateAppSoft(data) {
  return request({
	url: 'appsoft/update',
	method: 'post',
	data
  })
}
export function deleteAppSoft(id) {
  return request({
	url: 'appsoft/delete?id=' + id,
	method: 'get'
  })
}
export default { queryAppSoft, getAppSoft, getAllAppSoft, createAppSoft, updateAppSoft, deleteAppSoft }
