import request from '@/utils/request'
export function queryAdvSoft(data) {
  return request({
	url: 'advsoft/query',
	method: 'post',
	data
  })
}
export function getAdvSoft(id) {
  return request({
	url: 'advsoft/get?id=' + id,
	method: 'get'
  })
}
export function getAllAdvSoft(enable) {
  return request({
	url: 'advsoft/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createAdvSoft(data) {
  return request({
	url: 'advsoft/create',
	method: 'post',
	data
  })
}
export function updateAdvSoft(data) {
  return request({
	url: 'advsoft/update',
	method: 'post',
	data
  })
}
export function deleteAdvSoft(id) {
  return request({
	url: 'advsoft/delete?id=' + id,
	method: 'get'
  })
}
export default { queryAdvSoft, getAdvSoft, getAllAdvSoft, createAdvSoft, updateAdvSoft, deleteAdvSoft }
