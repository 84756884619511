import request from '@/utils/request'
export function queryAdvUpFile(data) {
  return request({
	url: 'advupfile/query',
	method: 'post',
	data
  })
}
export function getAdvUpFile(id) {
  return request({
	url: 'advupfile/get?id=' + id,
	method: 'get'
  })
}
export function getAllAdvUpFile(enable) {
  return request({
	url: 'advupfile/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createAdvUpFile(data) {
  return request({
	url: 'advupfile/create',
	method: 'post',
	data
  })
}
export function updateAdvUpFile(data) {
  return request({
	url: 'advupfile/update',
	method: 'post',
	data
  })
}
export function deleteAdvUpFile(id) {
  return request({
	url: 'advupfile/delete?id=' + id,
	method: 'get'
  })
}
export default { queryAdvUpFile, getAdvUpFile, getAllAdvUpFile, createAdvUpFile, updateAdvUpFile, deleteAdvUpFile }
