
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col :span="24">
        <el-form :inline="true" label-width="120px" @submit.native.prevent>
          <el-form-item label="收单行:">
            <el-select
              v-model="queryParams.acquirer"
              filterable
              placeholder="请选择收单行"
              @change="handleGetList"
            >
              <el-option
                v-for="item in acquirerOptions"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期:">
            <el-date-picker
              v-model="queryParams.groupBeginDate"
              :editable="false"
              :clearable="false"
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
              @change="handleGetList"
            />
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="queryParams.groupEndDate"
              :editable="false"
              :clearable="false"
              type="date"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="handleGetList"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleGetList"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
        <!-- <span class="">*金色代表银行/绿色代表地产商</span> -->
      </el-col></el-row
    >
    <el-row>
      <el-table
        ref="bustransrecord"
        id="bustransrecord"
        v-loading="loading"
        :data="billOption"
        row-key="id"
        :row-class-name="tableRowClassName"
        show-summary
        :summary-method="getSummaries"
        stripe
        border
       
      > <!-- :height="tableHeight * 0.65" -->
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />

        <el-table-column
          prop="billDate"
          align="center"
          :show-overflow-tooltip="true"
          label="对账日期"
        >
          <template slot-scope="scope">
            <el-link @click="handleBianJi(scope.row)" type="primary">{{
              scope.row.billDate
            }}</el-link>
          </template>
        </el-table-column>

        <el-table-column
          sortable
          prop="acquirerName"
          align="center"
          :show-overflow-tooltip="true"
          label="收单行"
        />
        <el-table-column align="center" label="银行">
          <el-table-column
            prop="bankCount"
            align="center"
            :show-overflow-tooltip="true"
            label="笔数"
          />
          <el-table-column
            sortable
            prop="bankTotal"
            align="right"
            :show-overflow-tooltip="true"
            label="金额"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.bankTotal != null">{{
                scope.row.bankTotal | formatCurrency
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="地产">
          <el-table-column
            prop="easterCount"
            align="center"
            :show-overflow-tooltip="true"
            label="笔数"
          >
          </el-table-column>
          <el-table-column
            sortable
            prop="easterTotal"
            align="right"
            :show-overflow-tooltip="true"
            label="金额"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.easterTotal != null">{{
                scope.row.easterTotal | formatCurrency
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          prop="billStatusName"
          align="center"
          :show-overflow-tooltip="true"
          label="状态"
        >
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.billStatus == '0'">
              帐平
            </span>
            <span style="color: red" v-if="scope.row.billStatus == '1'">
              不平
            </span>
            <span v-if="scope.row.billStatus == '2'"> 未对账 </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- <el-calendar :visible.sync="calendarOpen" v-model="currentDate">
    
    
     
   
      <template slot="dateCell" slot-scope="{ date, data }">
        <div @click="handleBianJi(data)" class="calendar-day" >
          <el-row>
            <el-col :span="12" class="" style="font-size: larger;">
              {{ data.day.split("-").slice(1).join("-") }}</el-col
            >
            <el-col :span="12">
              <span v-if="getData(data.day) != undefined" >
                {{ getData(data.day).billStatusName }}
              </span></el-col
            >
          </el-row>
          <el-row v-if="getData(data.day) != undefined" style="margin-top: 0.625rem;">
            <el-col :span="12" class="colorbank">银：{{ getData(data.day).bankTotal.toFixed(2) }} </el-col>
            <el-col :span="12" class="coloreaster">
              地：{{ getData(data.day).easterTotal.toFixed(2) }}
            </el-col>
          </el-row>
		  <el-row v-if="getData(data.day)!=undefined">
			  <el-col :span="12" class="colorbank">笔数：{{getData(data.day).bankCount}}</el-col>
			  <el-col :span="12" class="coloreaster">笔数：{{getData(data.day).easterCount}}</el-col>
		  </el-row>
        </div>
      </template>
    </el-calendar> -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      :fullscreen="true"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-row v-if="searchToggle" :gutter="20">
        <el-col :span="24">
          <el-form :inline="true" label-width="120px" @submit.native.prevent>
            <el-form-item label="参考号:">
              <el-input
                v-model="queryParams.refNo"
                placeholder="请输入参考号"
              />
            </el-form-item>
            <el-form-item label="收单行:">
              <el-select
                v-model="queryParams.acquirer"
                filterable
                placeholder="请选择收单行"
                @change="handleQuery"
              >
                <el-option
                  v-for="item in acquirerOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="商户号:">
              <el-select
                v-model="queryParams.branchNo"
                filterable
                placeholder="请选择商户号"
                @change="handleQuery"
              >
                <el-option
                  v-for="item in branchNoOption"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="开始日期:">
              <el-date-picker
                v-model="queryParams.beginDate"
                :editable="false"
                :clearable="false"
                type="date"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
                @change="handleQuery"
              />
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker
                v-model="queryParams.endDate"
                :editable="false"
                :clearable="false"
                type="date"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="handleQuery"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleQuery"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-row>
        <el-table
          ref="bustransrecord"
          id="bustransrecord"
          v-loading="loading"
          :data="dataRecord"
          row-key="id"
          stripe
          border
          :height="tableHeight * 0.65"
          :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
          @sort-change="handleSortable"
        >
          <el-table-column
            type="index"
            :index="handleIndexCalc"
            label="#"
            align="center"
          />

          <el-table-column
            sortable
            prop="billDate"
            align="center"
            :show-overflow-tooltip="true"
            label="交易时间"
          />
          <el-table-column
            sortable
            prop="acquirerName"
            align="center"
            :show-overflow-tooltip="true"
            label="收单行"
          />
          <el-table-column
            sortable
            prop="merchantCode"
            align="center"
            :show-overflow-tooltip="true"
            label="商户号"
          />

          <el-table-column
            sortable
            prop="terminalCode"
            align="center"
            :show-overflow-tooltip="true"
            label="设备编号"
          />
          <el-table-column
            sortable
            prop="issuerName"
            align="center"
            :show-overflow-tooltip="true"
            label="付款行"
          />
          <el-table-column
            sortable
            prop="sider"
            align="center"
            :show-overflow-tooltip="true"
            label="付款账号"
          />

          <el-table-column
            sortable
            prop="bankAmount"
            align="right"
            :show-overflow-tooltip="true"
            label="银行金额"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.bankAmount != null">{{
                scope.row.bankAmount | formatCurrency
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            sortable
            prop="easterAmount"
            align="right"
            :show-overflow-tooltip="true"
            label="地产金额"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.easterAmount != null">{{
                scope.row.easterAmount | formatCurrency
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            sortable
            prop="status"
            align="center"
            :show-overflow-tooltip="true"
            label="状态"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == '0'"> 帐平 </span>
              <span style="color: red" v-if="scope.row.status == '1'">
                银行长款
              </span>
              <span style="color: green" v-if="scope.row.status == '2'">
                地产长款
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageIndex"
          :limit.sync="queryParams.pageSize"
          @pagination="handleGetList"
        /> -->
      </el-row>
    </el-dialog>
  </div>
</template>
<style>
.colorbank {
  color: gold;
  font-size: 0.75rem;
}
.coloreaster {
  color: aquamarine;
  font-size: 0.75rem;
}
.colorfont {
  color: red;
}
</style>
<script>
import {
  queryBillGroupList,
  queryBillDetailList,
} from "@/api/Estate/bustransrecord";
import { getAllAcquirer } from "@/api/Estate/acquirer"; //收单行注入接口
import { getAllMerchant } from "@/api/ter/merchant"; //收单行注入接口

import { formatAmount } from "@/utils/filter";
import { formatDate } from "@/utils/index";
export default {
  name: "bustransrecord",
  data() {
    return {
      currentDate: new Date(),
      // 是否显示弹出层
      open: false,
      calendarOpen: !this.open,
      // 表单
      form: {},

      acquirerOptions: [],
      branchNoOption: [],
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      billOption: [],
      OrganOptions: [],
      dataRecord: [],
      gtotal: 0,
      gcount: 0,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        acquirer: "00",
        queryText: undefined,
        payStatus: "1",
        pageIndex: 1,
        pageSize: 10,
        orderby: "TransTime",
        sort: "descending",
        beginDate: this.$moment().startOf("month").format("YYYY-MM-DD"),
        endDate: formatDate(),
        groupBeginDate: this.$moment().startOf("month").format("YYYY-MM-DD"),
        groupEndDate: formatDate(),
      },
    };
  },
  async mounted() {},
  async created() {
    this.getAcquirer();
    this.getMerchant();
    this.handleGetList();
    this.loading = false;
    console.log(this.billOption, "billoption");
  },
  methods: {
    /** 查询业务数据列表 */
    getAcquirer() {
      getAllAcquirer(true).then((response) => {
        this.acquirerOptions = response.data;
      });
    },
    getMerchant() {
      getAllMerchant(true).then((response) => {
        this.branchNoOption = response.data;
        this.branchNoOption.unshift({ code: "", name: "全部" });
      });
    },
    getData(dval) {
      let ret = this.billOption.filter((item) => {
        return item.billDate === dval ? true : false;
      });

      if (ret.length > 0) {
        return ret[0];
      } else {
        return undefined;
      }
    },
    async handleGetList() {
      this.billOption = await this.getList();
    },
    getList() {
      let startDate = this.queryParams.groupBeginDate;
      let endDate = this.queryParams.groupEndDate;

      return new Promise((reslove, reject) => {
        queryBillGroupList({
          Acquirer: this.queryParams.acquirer,
          BeginDate: startDate,
          EndDate: endDate,
        })
          .then((response) => {
            reslove(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDetailData() {
      return new Promise((reslove, reject) => {
        queryBillDetailList({
          Acquirer: this.queryParams.acquirer,
          BeginDate: this.queryParams.beginDate,
          EndDate: this.queryParams.endDate,
          BranchNo: this.queryParams.branchNo,
          RefNo: this.queryParams.refNo,
        })
          .then((response) => {
            this.loading = false;
            reslove(response.data);
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
    handleBianJi(data) {
      this.open = true;
      this.queryParams.beginDate = data.billDate;
      this.queryParams.endDate = data.billDate;
      this.handleQuery();
    },
    async handleQuery() {
      this.dataRecord = await this.getDetailData();
      // this.handleGetList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams = {
        customName: "",
        crmTransNo: "",
        bankFlowNo: "",
        terminalCode: "",
        orgName: "",
        payAmount: "",
        busType: "",
        BusTypeOption: "",
        beginDate: this.$moment().startOf("month").format("YYYY-MM-DD"),
        endDate: formatDate(),
      };
      this.handleQuery();
    },

    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.handleGetList();
    },
    // 表单重置
    reset() {
      this.form = {
        bustransrecordName: undefined,
        bustransrecordNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.handleGetList();
    },
    tableRowClassName({ row }) {
      console.log(row.billStatus);
      if (row.billStatus === 1) {
        return "warning-row";
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property])); 
        if (column.property === "bankCount"||column.property === "bankTotal"||column.property === "easterCount"||column.property === "easterTotal") {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        } else {
          sums[index] = "--";
        }
      });

      return sums;
    },
    //getSummaries(param) {

    //   const { columns, data } = param;
    //   const sums = [];
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = "合计";
    //       return;
    //     }

    //   //   bankTotal easterCount easterTotal
    //     if (
    //       !values.every((value) => isNaN(value)) &&
    //       column.property === "bankCount"
    //     ) {
    //        const values = data.map((item) => Number(item[column.property]));
    //     console.log(values,'===========================================================')
    //      // sums[index] = Number.parseFloat(value).toFixed(2);
    //     } else {
    //       sums[index] = "/";
    //     }
    //   });
    //   //  sums[0] = "合计";
    //   return sums;
    // },
  },
  filters: {
    formatCurrency(data) {
      return formatAmount(data);
    },
  },
  watch: {
    // async currentDate(nval, oVal) {
    //   this.billOption = await this.handleGetList();
    // },
    // queryParams: {
    //   // async handler(v) {
    //   //   this.billOption = await this.handleGetList();
    //   // },
    //   immediate: true,
    //   deep: true,
    // },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
/deep/.el-table .warning-row {
  color: rgba(243, 0, 0, 0.5);
}
</style>
 

