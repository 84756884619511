//格式化金额
export function formatAmount(data) {
    return Number.parseFloat(data).toFixed(2);
};
//计算日期
export function calaDate(AddDayCount) {
    let aData = new Date();
    aData.setDate(aData.getDate() + AddDayCount);
    return (
        aData.getFullYear() +
        "-" +
        (aData.getMonth() + 1) +
        "-" +
        aData.getDate()
    );
};
