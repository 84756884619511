
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="员工编号:">
            <el-input
              v-model="queryParams.code"
              placeholder="请输入员工编号"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="员工姓名">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入员工姓名"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="所属机构">
            <el-input
              v-model="queryParams.organName"
              placeholder="请输入所属机构"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" class="mb8">
      <el-button
        v-permission="['PRIV_AUTHUSER_CREATE']"
        type="success"
        icon="el-icon-plus"
        size="mini"
        @click="handleCreate"
        >新增</el-button
      >
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="authuser"
        v-loading="loading"
        :data="dataAuthUser"
        row-key="id"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />
        <el-table-column
          sortable
          prop="code"
          align="center"
          :show-overflow-tooltip="true"
          label="员工编号"
        />
        <el-table-column
          sortable
          prop="name"
          align="center"
          :show-overflow-tooltip="true"
          label="员工姓名"
        />
        <el-table-column
          sortable
          prop="organName"
          align="center"
          :show-overflow-tooltip="true"
          label="所属机构"
        />

        <el-table-column
          sortable
          prop="auImageUrl"
          align="center"
          :show-overflow-tooltip="true"
          label="图片路径"
        >
          <template slot-scope="scope">
          
            <a class="downFile" target="_blank" :href="scope.row.auImageUrl">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.auImageUrl"
            ></el-image></a>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="remark"
          align="center"
          :show-overflow-tooltip="true"
          label="备注"
        />
        <el-table-column sortable prop="enable" align="center" label="是否启用">
          <template slot-scope="scope">
            <i
              :style="scope.row.enable === true ? 'color:green' : 'color:red'"
              :class="
                scope.row.enable === true ? 'el-icon-success ' : 'el-icon-error'
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-permission="['PRIV_AUTHUSER_UPDATE']"
                type="info"
                size="mini"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
              />
              <el-button
                v-permission="['PRIV_AUTHUSER_DELETE']"
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
              />
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row style="width: 75%">
          <el-col :span="24">
            <el-form-item label="所属机构" prop="organCode">
              <el-select
              
                v-model="form.organCode"
                filterable
                placeholder="请选择所属机构"
                style="width: 100%"
              >
                <el-option
                  v-for="item in OrganOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="员工姓名" maxlength="255" prop="name">
              <el-select
                style="width: 100%"
                v-model="form.name"
                @change="currentSel"
                filterable
                reserve-keyword
                placeholder="请输入员工姓名"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.userID"
                  :label="item.userName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="员工编号" maxlength="255" prop="code">
              <el-input readonly v-model="form.code" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注" maxlength="255" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否启用" prop="enable">
              <el-checkbox v-model="form.enable" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="cu-avatar">
          <el-upload
            class="avatar-uploader"
            :action="auImmUploadUrl"
            :headers="headers"
            :on-change="fileChange"
            accept=".jpg,.jpeg,.png"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
            :show-file-list="true"
            :on-exceed="onExceed"
          >
            <img
              v-if="form.auImageUrl"
              fits="cover"
              :src="form.auImageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
          <div class="avatar-span">用户头像上传</div>
          <div slot="tip" class="el-upload__tip">图片文件应小于4M</div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryAuthUser,
  createAuthUser,
  updateAuthUser,
  deleteAuthUser,
} from "@/api/Estate/authuser";
import { queryUsers, getAllUser } from "@/api/users/users";
import { getAllEstateOrgan } from "@/api/Estate/estateorgan";
import { getToken } from "@/utils/auth";

export default {
  name: "authuser",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: { auImageUrl: "" },
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      // 合计条数
      total: 0,
      options: [],
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
      },
      OrganOptions: [],
      auImmUploadUrl: process.env.VUE_APP_BASE_API + "/AuthUser/AuthImgUpload",
      headers: { SYSTOKEN: getToken() },
      // 加载中
      loading: false,
      // 图片路径
      imageUrl: "",
      // 列表
      dataAuthUser: [],
      // 表单校验
      rules: {
        code: [
          { required: true, message: "员工编号不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "员工姓名不能为空", trigger: "blur" },
        ],
        auImageUrl: [
          { required: true, message: "图片路径不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUser();
    this.getOrgan();
    this.getList();
  },
  methods: {
    /** 查询列表 */
    getList() {
      this.loading = true;
      queryAuthUser(this.queryParams).then((response) => {
        this.dataAuthUser = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams.queryText = "";
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "修改";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      console.log(row, "-------------------------------------------");
      this.$confirm('是否确认删除名称为"' + row.name + '"的?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAuthUser(row.id).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            updateAuthUser(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          } else {
            createAuthUser(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        authuserName: undefined,
        authuserNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
    beforeAvatarUpload(file) {  
      const isLt4M = file.size / 1024 / 1024 < 4;      
      if (!isLt4M) {
        this.$message.error("上传头像图片大小不能超过 4MB!");
      }
      return   isLt4M;
    },
    // 图片上传成功
    handleAvatarSuccess(response, file) {
      if (response.statusCode === 200) {
        this.form.auImageUrl = response.data;
        this.$message({
          message: "图片上传成功",
          type: "success",
        });
      }
    },
    fileChange(file, fileList) {
      console.log(fileList,'==============');
      if (fileList.length > 1) {
        fileList.splice(0, 1);
          console.log(fileList,'=33333333333========');
        
      }
    },
    currentSel(item) {
      this.form.name = item.userName;
      this.form.code = item.userID;
    },
    //   获取对应公司的终端设备列表
    getOrgan() {
      this.loading = true;

      getAllEstateOrgan(true).then((response) => {
        this.OrganOptions = response.data;

        this.loading = false;
      });
    },
    async getUser() {
      this.loading = true;
      let resp = await getAllUser(true);
      this.loading = false;
      this.options = resp.data;
    },
    
    onExceed(file) {
      console.log(file, "0000000000000");
      this.fileList = [
        {
          name: file[0].name,
        },
      ];
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.cu-avatar {
  position: absolute;
  width: 20%;
  right: 35px;
  top: 70px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-span {
  margin-top: 10px;
  width: 178px;
  text-align: center;
  color: #409eff;
}
.el-upload__tip {
  margin-top: 10px;
  width: 178px;
  text-align: center;
  color: #ff0000;
}
</style>
