
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="文件名称:">
            <el-input
              v-model="queryParams.fileName"
              placeholder="请输入文件名称 "
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
           <el-form-item label="设备编号:">
            <el-input
              v-model="queryParams.terminalCode"
              placeholder="请输入设备编号"
               
            />
          </el-form-item>
          <el-form-item label="日期范围:">
            <el-date-picker
              :clearable="false"
              v-model="queryParams.createTime"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重4置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" class="mb8">
      <el-button
        v-permission="['PRIV_UPLOAD_CREATE']"
        type="success"
        icon="el-icon-plus"
        size="mini"
        @click="handleCreate"
        >新增终端日志上传记录
      </el-button>
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="upload"
        v-loading="loading"
        :data="dataUpLoad"
        row-key="id"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />
        <el-table-column
          sortable
          prop="fileName"
          align="center"
          :show-overflow-tooltip="true"
          label="文件名称 "
        >
          <template slot-scope="scope">
            <a class="downFile" target="_blank" :href="scope.row.fileLinkUrl">{{
              scope.row.fileName
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="remoteIP"
          align="center"
          :show-overflow-tooltip="true"
          label="远程名称 "
        />
        <!-- <el-table-column sortable prop="fileSaveAs" align="center" :show-overflow-tooltip="true" label="另存本地名称 "  /> -->
        <el-table-column
          sortable
          prop="terminalCode"
          align="center"
          :show-overflow-tooltip="true"
          label="设备编号 "
        />
        <el-table-column
          sortable
          prop="createTime"
          align="center"
          :show-overflow-tooltip="true"
          label="上传时间 "
        />
        <!-- <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">
		  <template slot-scope="scope">
			<el-button-group>
			  <el-button v-permission="['PRIV_UPLOAD_UPDATE']" type="info" size="mini" icon="el-icon-edit" @click="handleUpdate(scope.row)" />
			  <el-button v-permission="['PRIV_UPLOAD_DELETE']" type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row)" />
			</el-button-group>
		  </template>
		</el-table-column> -->
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="文件名称 " maxlength="150" prop="fileName">
              <el-input v-model="form.fileName" placeholder="请输入文件名称 " />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="远程名称 " maxlength="30" prop="remoteIP">
              <el-input v-model="form.remoteIP" placeholder="请输入远程名称 " />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="另存本地名称 " maxlength="0" prop="fileSaveAs">
              <el-input
                v-model="form.fileSaveAs"
                placeholder="请输入另存本地名称 "
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备编号 " maxlength="20" prop="terminalCode">
              <el-input
                v-model="form.terminalCode"
                placeholder="请输入终端号 "
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传时间 " maxlength="0" prop="createTime">
              <el-input
                v-model="form.createTime"
                placeholder="请输入上传时间 "
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryUpLoad,
  createUpLoad,
  updateUpLoad,
  deleteUpLoad,
} from "@/api/ter/upload";
export default {
  name: "upload",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
		  FileType:"Log",
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
         createTime: [this.calaDate(-1), this.calaDate(0)],
      },
      // 终端日志上传记录  列表
      dataUpLoad: [],
      // 表单校验
      rules: {
        createTime: [
          { required: true, message: "上传时间 编号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    calaDate(AddDayCount) {
      let aData = new Date();
      aData.setDate(aData.getDate() + AddDayCount);
      return (
        aData.getFullYear() +
        "-" +
        (aData.getMonth() + 1) +
        "-" +
        aData.getDate()
      );
    },
    /** 查询终端日志上传记录  列表 */
    getList() {
      this.loading = true;
      queryUpLoad(this.queryParams).then((response) => {
        this.dataUpLoad = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams.queryText = "";
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "添加终端日志上传记录  ";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "修改终端日志上传记录  ";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.uploadName + '"的终端日志上传记录  ?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteUpLoad(row.id).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            updateUpLoad(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          } else {
            createUpLoad(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        uploadName: undefined,
        uploadNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
