import request from '@/utils/request'
export function queryBusTransRecord(data) {
  return request({
	url: 'bustransrecord/query',
	method: 'post',
	data
  })
}
export function groupQuery(data) {
	return request({
	  url: 'bustransrecord/groupQuery',
	  method: 'post',
	  data
	})
  }
export function getBusTransRecord(id) {
  return request({
	url: 'bustransrecord/get?id=' + id,
	method: 'get'
  })
}
export function getAllBusTransRecord(enable) {
  return request({
	url: 'bustransrecord/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createBusTransRecord(data) {
  return request({
	url: 'bustransrecord/create',
	method: 'post',
	data
  })
}
export function updateBusTransRecord(data) {
  return request({
	url: 'bustransrecord/update',
	method: 'post',
	data
  })
}
export function deleteBusTransRecord(id) {
  return request({
	url: 'bustransrecord/delete?id=' + id,
	method: 'get'
  })
}

export function exportExcel(data) {
	return request({
	  url: 'bustransrecord/exportExcel',
	  method: 'post',
	  data
	})
  }
  export function exportGroupExcel(data) {
	return request({
	  url: 'bustransrecord/exportGroupExcel',
	  method: 'post',
	  data
	})
  }
 
  export function queryBillGroupList(data) {
	return request({
	  url: 'bustransrecord/QueryBillGroupList',
	  method: 'post',
	  data
	})
  }
  export function queryBillDetailList(data) {
	return request({
	  url: 'bustransrecord/QueryBillDetailList',
	  method: 'post',
	  data
	})
  }
  // QueryBillDetailList QueryBillGroupList
export default { queryBusTransRecord, getBusTransRecord, getAllBusTransRecord, createBusTransRecord, updateBusTransRecord, deleteBusTransRecord,exportExcel,
	groupQuery,exportGroupExcel,queryBillGroupList,queryBillDetailList }
