import request from '@/utils/request'
export function queryPayMode(data) {
  return request({
	url: 'paymode/query',
	method: 'post',
	data
  })
}
export function getPayMode(id) {
  return request({
	url: 'paymode/get?id=' + id,
	method: 'get'
  })
}
export function getAllPayMode(enable) {
  return request({
	url: 'paymode/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createPayMode(data) {
  return request({
	url: 'paymode/create',
	method: 'post',
	data
  })
}
export function updatePayMode(data) {
  return request({
	url: 'paymode/update',
	method: 'post',
	data
  })
}
export function deletePayMode(id) {
  return request({
	url: 'paymode/delete?code=' + id,
	method: 'get'
  })
}
export default { queryPayMode, getPayMode, getAllPayMode, createPayMode, updatePayMode, deletePayMode }
