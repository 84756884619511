
<template>
  <div class="app-container">
    <div class="block">
      <span class="demonstration">请选择记账月份</span>
      <el-date-picker v-model="value2" type="month" placeholder="选择月">
      </el-date-picker>
    </div>
    <el-row :gutter="20">
      <el-col :span="4" v-for="(item, index) in billOption" :key="index"
        ><div class="lightred color-box">
          <div>{{ item.daily_date }}</div>
          <div>帐平</div>
          <div>银行记账:10.00</div>
          <div>公司记账:20.09</div>
          <el-button type="warning">重新对账</el-button>
          <el-button type="danger">对账明细</el-button>
        </div></el-col
      >
    </el-row>
  </div>
</template>
<script>
import { getAllEstateOrgan } from "@/api/Estate/estateorgan";

import { formatDate } from "@/utils/index";
export default {
  name: "bustransrecord",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      billOption: [ 
      ],
      OrganOptions: [],
      gtotal: 0,
      gcount: 0,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        queryText: undefined,
        payStatus: "1",
        pageIndex: 1,
        pageSize: 10,
        orderby: "TransTime",
        sort: "descending",
        beginDate: formatDate(-7),
        endDate: formatDate(),
      },
    };
  },
  created() {},
  methods: {
    /** 查询业务数据列表 */
    getList() {
      this.loading = true;
      queryBusTransRecord(this.queryParams).then((response) => {
        this.dataBusTransRecord = response.data.dataSource;
        this.total = response.data.totalCount;
        this.gtotal = response.data.totalAmount;
        this.gcount = response.data.pCount;
        this.loading = false;
      });
    },
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams = {
        customName: "",
        crmTransNo: "",
        bankFlowNo: "",
        terminalCode: "",
        orgName: "",
        payAmount: "",
        busType: "",
        BusTypeOption: "",
        beginDate: formatDate(-7),
        endDate: formatDate(),
      };
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "添加业务数据";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "修改业务数据";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.bustransrecordName + '"的业务数据?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteBusTransRecord(row.id).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },

    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        bustransrecordName: undefined,
        bustransrecordNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.paystatus {
  color: darkcyan;
}
.paystatusred {
  color: rgb(139, 0, 0);
}

.color-box {
  // position: relative;
  border-radius: 4px;
  padding: 20px;
  margin: 5px 0;
  height: 114px;
  box-sizing: border-box;
  // color: #fff;
  // font-size: 14px;
}

.lightred {
  background: rgb(13, 194, 149);
}
</style>
