
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="设备编号:">
            <el-input
              v-model="queryParams.terminalCode"
              placeholder="请输入设备编号"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>

          <el-form-item label="公司名称" prop="orgName">
            <el-select 
              @change="handleQuery"
              v-model="queryParams.OrganName"
              filterable
              placeholder="请选择公司名称"
              style="width: 100%"
            >
              <el-option
                v-for="item in OrganOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="项目名称:">
            <el-input
              v-model="queryParams.projectName"
              placeholder="请输入项目名称"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="房间名称:">
            <el-input
              v-model="queryParams.roomName"
              placeholder="请输入房间名称"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="客户名称:">
            <el-input
              v-model="queryParams.customName"
              placeholder="请输入客户名称"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="图片类型:">
            <el-input
              v-model="queryParams.imgType"
              placeholder="请输入机构名称"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="开始日期:">
            <el-date-picker
              v-model="queryParams.beginDate"
              :editable="false"
              :clearable="false"
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
              @change="handleQuery"
            />
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="queryParams.endDate"
              :editable="false"
              :clearable="false"
              type="date"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="handleQuery"
            />
          </el-form-item>
          <el-form-item label="业务类型:">
            <el-select
              v-model="queryParams.busType"
              filterable
              placeholder="请选择业务类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in BusTypeOption"
                :key="item.id"
                :label="item.code + ' | ' + item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" class="mb8">
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-download"
          @click="handleDownFile"
        />
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="busimg"
        v-loading="loading"
        :data="dataBusImg"
        row-key="id"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />
        <el-table-column
          sortable
          prop="uploadTime"
          align="center"
          :show-overflow-tooltip="true"
          label="上传时间"
        />
        <el-table-column
          sortable
          prop="transFlowNo"
          align="center"
          :show-overflow-tooltip="true"
          label="交易流水号"
        />
        <el-table-column
          sortable
          prop="terminalCode"
          align="center"
          :show-overflow-tooltip="true"
          label="设备编号"
        />

        <el-table-column
          sortable
          prop="busTypeName"
          align="center"
          :show-overflow-tooltip="true"
          label="业务类型"
        />
        <el-table-column
          sortable
          prop="organName"
          align="center"
          :show-overflow-tooltip="true"
          label="机构名称"
        />

        <el-table-column
          sortable
          prop="projectName"
          align="center"
          :show-overflow-tooltip="true"
          label="项目名称"
        />

        <el-table-column
          sortable
          prop="stageProjName"
          align="center"
          :show-overflow-tooltip="true"
          label="分期名称"
        />
        >
        <!-- <el-table-column
          sortable
          prop="roomCode"
          align="center"
          :show-overflow-tooltip="true"
          label="房间号"
        /> -->
        <el-table-column
          sortable
          prop="roomName"
          align="center"
          :show-overflow-tooltip="true"
          label="房间名称"
        />
        <el-table-column
          sortable
          prop="customName"
          align="center"
          :show-overflow-tooltip="true"
          label="客户名称"
        />
        <el-table-column
          sortable
          prop="imgType"
          align="center"
          :show-overflow-tooltip="true"
          label="图片类型"
        />
        <el-table-column
          sortable
          prop="newFilePath"
          align="center"
          :show-overflow-tooltip="true"
          label="图像"
        >
          <template slot-scope="scope">
            <a class="downFile" target="_blank" :href="scope.row.newFilePath">
              预览
            </a>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备编号" maxlength="50" prop="terminalCode">
              <el-input
                v-model="form.terminalCode"
                placeholder="请输入设备编号"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传文件名称" maxlength="100" prop="fileName">
              <el-input
                v-model="form.fileName"
                placeholder="请输入上传文件名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="远程IP" maxlength="100" prop="remoteIp">
              <el-input v-model="form.remoteIp" placeholder="请输入远程IP" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="服务器图片路径"
              maxlength="500"
              prop="newFilePath"
            >
              <el-input
                v-model="form.newFilePath"
                placeholder="请输入服务器图片路径"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传时间" maxlength="0" prop="uploadTime">
              <el-input
                v-model="form.uploadTime"
                placeholder="请输入上传时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="业务类型" maxlength="20" prop="busType">
              <el-input v-model="form.busType" placeholder="请输入业务类型" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="机构名称" maxlength="100" prop="organName">
              <el-input v-model="form.organName" placeholder="请输入机构名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="机构编号" maxlength="50" prop="organCode">
              <el-input v-model="form.organCode" placeholder="请输入机构编号" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目名称" maxlength="50" prop="projectCode">
              <el-input
                v-model="form.projectCode"
                placeholder="请输入项目名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目编号" maxlength="100" prop="projectName">
              <el-input
                v-model="form.projectName"
                placeholder="请输入项目编号"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分期名称" maxlength="255" prop="stageProjName">
              <el-input
                v-model="form.stageProjName"
                placeholder="请输入分期名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分期编号" maxlength="255" prop="stageProjCode">
              <el-input
                v-model="form.stageProjCode"
                placeholder="请输入分期编号"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="房间号" maxlength="50" prop="roomCode">
              <el-input v-model="form.roomCode" placeholder="请输入房间号" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="房间名称" maxlength="100" prop="roomName">
              <el-input v-model="form.roomName" placeholder="请输入房间名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="客户名称" maxlength="50" prop="customName">
              <el-input
                v-model="form.customName"
                placeholder="请输入客户名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图片类型" maxlength="20" prop="imgType">
              <el-input v-model="form.imgType" placeholder="请输入图片类型" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="交易流水号" maxlength="50" prop="transFlowNo">
              <el-input
                v-model="form.transFlowNo"
                placeholder="请输入交易流水号"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryBusImg,
  createBusImg,
  updateBusImg,
  exportZipFile,
} from "@/api/Estate/busimg";
import { getAllBusType } from "@/api/Estate/bustype";
import { getAllEstateOrgan } from "@/api/Estate/estateorgan";
import { formatDate } from "@/utils/index";
export default {
  name: "busimg",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        orderby: "UploadTime",
        sort: "descending",
        IsDownFile: false,
        beginDate: formatDate(-7),
        endDate: formatDate(),
      },
      // 业务图片列表
      dataBusImg: [],
      BusTypeOption: [],
      OrganOptions: [],
      // 表单校验
      rules: {
        terminalCode: [
          { required: true, message: "设备编号不能为空", trigger: "blur" },
        ],
        fileName: [
          {
            required: true,
            message: "上传文件名称编号不能为空",
            trigger: "blur",
          },
        ],
        uploadTime: [
          { required: true, message: "上传时间编号不能为空", trigger: "blur" },
        ],
        busType: [
          { required: true, message: "业务类型编号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    getAllBusType(true).then((response) => {
      console.log(response);
      this.BusTypeOption = response.data;
    });
    this.getList();
        this.getOrgan();
  },
  methods: {
    /** 查询业务图片列表 */
    getList() {
      this.loading = true;
      queryBusImg(this.queryParams).then((response) => {
        this.dataBusImg = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },
    getOrgan() {
      this.loading = true;

      getAllEstateOrgan(true).then((response) => {
        this.OrganOptions = response.data;
        this.OrganOptions.unshift({ name:'全部',code:''});
        console.log(this.OrganOptions,'=======================');
        this.loading = false;
      });
    },
    calaDate(AddDayCount) {
      let aData = new Date();
      aData.setDate(aData.getDate() + AddDayCount);
      return (
        aData.getFullYear() +
        "-" +
        (aData.getMonth() + 1) +
        "-" +
        aData.getDate()
      );
    },
    handleQuery() {
      this.queryParams.IsDownFile = false;
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams = {
        terminalCode: "",
        fileName: "",
        beginDate: formatDate(-7),
        endDate: formatDate(),
      };
      this.getList();
    },
    /** 下载按钮操作 */
    handleDownFile() {
      this.queryParams.IsDownFile = true;
      this.loading = true;
      exportZipFile(this.queryParams).then((response) => {
        console.log("================", response);
        this.loading = false;
        let data = response.data;
        this.saveAs(data);
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "修改业务图片";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.busimgName + '"的业务图片?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteBusImg(row.id).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            updateBusImg(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          } else {
            createBusImg(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        busimgName: undefined,
        busimgNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
    saveAs(obj) {
      //当然可以自定义简单的下载文件实现方式
      console.log(obj, "222222222");
      var tmpa = document.createElement("a");
      tmpa.download = new Date().getTime() + ".zip";
      tmpa.href = obj; //URL.createObjectURL(obj); //绑定a标签
      tmpa.click(); //模拟点击实现下载

      // setTimeout(function () {
      //   //延时释放
      //   URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
      // }, 100);
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.downFile {
  color: rgb(12, 189, 92);
}
</style>
