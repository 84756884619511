import request from '@/utils/request'
export function queryBusImg(data) {
  return request({
	url: 'busimg/query',
	method: 'post',
	data
  })
}
export function getBusImg(id) {
  return request({
	url: 'busimg/get?id=' + id,
	method: 'get'
  })
}
export function getAllBusImg(enable) {
  return request({
	url: 'busimg/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createBusImg(data) {
  return request({
	url: 'busimg/create',
	method: 'post',
	data
  })
}
export function updateBusImg(data) {
  return request({
	url: 'busimg/update',
	method: 'post',
	data
  })
}
export function exportZipFile(data) {
	return request({
		url: 'busimg/exportZipFile',
		method: 'post',
		data
	  })
}
export default { queryBusImg, getBusImg, getAllBusImg, createBusImg, updateBusImg, exportZipFile }
