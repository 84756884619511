import request from '@/utils/request'
export function queryTicketParam(data) {
  return request({
	url: 'ticketparam/query',
	method: 'post',
	data
  })
}
export function getTicketParam(id) {
  return request({
	url: 'ticketparam/get?id=' + id,
	method: 'get'
  })
}
export function getAllTicketParam(enable) {
  return request({
	url: 'ticketparam/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createTicketParam(data) {
  return request({
	url: 'ticketparam/create',
	method: 'post',
	data
  })
}
export function updateTicketParam(data) {
  return request({
	url: 'ticketparam/update',
	method: 'post',
	data
  })
}
export function deleteTicketParam(id) {
  return request({
	url: 'ticketparam/delete?id=' + id,
	method: 'get'
  })
}
export default { queryTicketParam, getTicketParam, getAllTicketParam, createTicketParam, updateTicketParam, deleteTicketParam }
