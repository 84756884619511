import request from '@/utils/request'
export function queryBusType(data) {
  return request({
	url: 'bustype/query',
	method: 'post',
	data
  })
}
export function getBusType(id) {
  return request({
	url: 'bustype/get?id=' + id,
	method: 'get'
  })
}
export function getAllBusType(enable) {
  return request({
	url: 'bustype/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createBusType(data) {
  return request({
	url: 'bustype/create',
	method: 'post',
	data
  })
}
export function updateBusType(data) {
  return request({
	url: 'bustype/update',
	method: 'post',
	data
  })
}
export function deleteBusType(id) {
  return request({
	url: 'bustype/delete?code=' + id,
	method: 'get'
  })
}
export default { queryBusType, getBusType, getAllBusType, createBusType, updateBusType, deleteBusType }
