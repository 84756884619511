
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model="queryParams.upBeforeFileName"
              placeholder="请输入上传时文件名称 "
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="queryParams.version"
              placeholder="请输入版本号 "
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="queryParams.advID"
              clearable
              filterable
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in appSoftOption"
                :key="item.id"
                clearable
                :label="item.softCode + ' | ' + item.softName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" class="mb8">
      <el-button
        v-permission="['PRIV_ADVUPFILE_CREATE']"
        type="success"
        icon="el-icon-plus"
        size="mini"
        @click="handleCreate"
        >新增上传文件
      </el-button>
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="advupfile"
        v-loading="loading"
        :data="dataAdvUpFile"
        row-key="id"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />
        <el-table-column
          sortable
          prop="softName"
          align="center"
          :show-overflow-tooltip="true"
          label="轮播图分类 "
        />
        <el-table-column
          sortable
          prop="upBeforeFileName"
          align="center"
          :show-overflow-tooltip="true"
          label="上传时文件名称 "
        >
          <template slot-scope="scope">
            <a class="downFile" target="_blank" :href="scope.row.upFileName">{{
              scope.row.upBeforeFileName
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="version"
          align="center"
          :show-overflow-tooltip="true"
          label="版本号 "
        />
        <el-table-column
          sortable
          prop="createName"
          align="center"
          :show-overflow-tooltip="true"
          label="上传人 "
        />

        <el-table-column
          sortable
          prop="publishTime"
          align="center"
          :show-overflow-tooltip="true"
          label="发布时间"
        />
        <el-table-column
          sortable
          prop="remark"
          align="center"
          :show-overflow-tooltip="true"
          label="备注 "
        />

        <el-table-column
          sortable
          prop="createTime"
          align="center"
          :show-overflow-tooltip="true"
          label="创建时间"
        />

        <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-permission="['PRIV_ADVUPFILE_UPDATE']"
                type="info"
                size="mini"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
              />
              <el-button
                v-permission="['PRIV_ADVUPFILE_DELETE']"
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
              />
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>

    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="轮播图分类 " maxlength="50" prop="AdvID">
              <el-select
                v-model="form.AdvID"
                clearable
                filterable
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in appSoftOption"
                  :key="item.id"
                  clearable
                  :label="item.softCode + ' | ' + item.softName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="版本号 " maxlength="80" prop="version">
              <el-input v-model="form.version" placeholder="请输入版本号 " />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="发布时间" maxlength="0" prop="publishTime">
              <el-date-picker
                v-model="form.publishTime"
                style="width: 100%"
                type="date"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="上传文件"
              maxlength="550"
              prop="upBeforeFileName"
            >
              <el-upload
                class="avatar-uploader"
                accept=".zip"
                :action="avatarUploadUrl"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :show-file-list="true"
                :before-upload="beforeUpload"
                :limit="1"
                :file-list="form.fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传zip文件，且不超过250M
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注 " maxlength="600" prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="form.remark"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryAdvUpFile,
  createAdvUpFile,
  updateAdvUpFile,
  deleteAdvUpFile,
} from "@/api/ter/advupfile";
import { getAllAdvSoft } from "@/api/ter/advsoft";
import { getToken } from "@/utils/auth";
export default {
  name: "advupfile",
  data() {
    return {
      // 是否显示弹出层
      open: false,

      appSoftOption: [],
      avatarUploadUrl:
        process.env.VUE_APP_BASE_API + "/AdvUpFile/AdvSoftUpload",
      headers: { SYSTOKEN: getToken() },

      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
      },
      // 上传文件  列表
      dataAdvUpFile: [],

      // 表单校验
      rules: {
        upBeforeFileName: [
          {
            required: true,
            message: "上传时文件名称不能为空",
            trigger: "blur",
          },
        ],
        publishTime: [
          {
            required: true,
            message: "发布时间不能为空",
            trigger: "blur",
          },
        ],
        version: [
          { required: true, message: "版本编号不能为空", trigger: "blur" },
        ],

        AdvID: [
          {
            required: true,
            message: "轮播图分类不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
    getAllAdvSoft(true).then((response) => {
      this.appSoftOption = response.data;
    });
  },

  methods: {
    /** 查询上传文件  列表 */
    getList() {
      this.form.fileList = [];
      this.loading = true;
      queryAdvUpFile(this.queryParams).then((response) => {
        this.dataAdvUpFile = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },

    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams.upBeforeFileName = "";
      this.queryParams.version = "";
      this.queryParams.advID = "";
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "轮播图上传";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "轮播图上传";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.upFileName + '"的上传文件  ?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteAdvUpFile(row.id).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            this.loading = true;
            updateAdvUpFile(this.form).then((response) => {
              this.loading = false;
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;

                this.getList();
              }
            });
          } else {
            this.loading = true;
            createAdvUpFile(this.form).then((response) => {
              this.loading = false;
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        AdvID: undefined,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
        upBeforeFileName: undefined,
        upFileName: undefined,
        fileList: [],
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      console.log(
        this.form.fileList,
        "-------------------------------------------"
      );
      this.divShow = "query";
      this.reset();
      this.$refs["form"].clearValidate();
      this.getList();
    },
    beforeUpload(file) {
      const isJPG = file.type === "application/x-zip-compressed";
      const isLt50M = file.size / 1024 / 1024 < 250;

      if (!isJPG) {
        this.$message.error("上传文件只能是zip格式!");
      }
      if (!isLt50M) {
        this.$message.error("上传文件大小不能超过 250MB!");
      }
      console.log(isLt50M, "============================================");
      return isJPG && isLt50M;
    },
    handleAvatarSuccess(response, file) {
      if (response.statusCode === 200) {
        console.log(response);
        this.form.upBeforeFileName = response.data.upBeforeFileName;
        this.form.upFileName = response.data.upFileName;

        this.$message({
          message: "图片上传成功",
          type: "success",
        });
      } else {
        this.$message({
          message: response.message,
          type: "error",
        });
        console.log(response.message);
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.showTable {
  background-color: #ffffff;
  width: 80%;
  height: 100%;
  margin: 25px auto;
}
.foot {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.headerGray {
  width: 100%;
  height: 100%;
  background: #ccc;
}
.downFile:link {
  color: blue;
} /* 未被访问的链接 蓝色 */
// .downFile:visited {color: blue} /* 已被访问过的链接 蓝色 */
// .downFile:hover {color: blue} /* 鼠标悬浮在上的链接 蓝色 */
// .downFile:active {color: blue} /* 鼠标点中激活链接 蓝色 */
</style>
