
<template>
  <div class="app-container">
    <el-row :gutter="24" type="flex">
      <el-col :span="24">
        <el-button
          style="margin-bottom: 20px"
          v-permission="['PRIV_TERMINAL_CREATE']"
          type="success"
          icon="el-icon-plus"
          size="mini"
          @click="handleCreate"
          >新增终端设备表
        </el-button>
        <el-dropdown @command="handleCommand" style="margin-left: 10px">
          <el-button type="primary" icon="el-icon-unlock">
            发送终端指令<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in cmdOptions"
              :key="index"
              v-text="item.cmdName"
              :command="item.cmdCode"
            ></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          style="margin-bottom: 20px; margin-left: 10px"
          v-permission="['PRIV_TERMINAL_CREATE']"
          type="info"
          icon="el-icon-doc"
          size="mini"
          @click="handleBatchUpdate"
          >暂收款时间设置
        </el-button>
        <!-- <el-button-group style="margin-left: auto">
          <el-button
            size="mini"
            plain
            type="info"
            icon="el-icon-search"
            @click="handleSearch"
          />
          <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
        </el-button-group>
        </el-row> -->
        <!-- <el-row> -->
        <el-table
          ref="terminalTable"
          v-loading="loading"
          :data="dataTerminal"
          row-key="id"
          stripe
          border
          :height="tableHeight * 0.65"
          :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
          @sort-change="handleSortable"
        >
          <el-table-column
            sortable
            type="selection"
            width="55"
            align="center"
          />
          <!-- <el-table-column
            type="index"
            :index="handleIndexCalc"
            label="#"
            align="center"
          /> -->
          <el-table-column
            prop="terminalCode"
            align="center"
            :show-overflow-tooltip="true"
            label="设备编号 "
          />
          <el-table-column
            prop="terminalName"
            align="center"
            :show-overflow-tooltip="true"
            label="设备名称 "
          />

          <el-table-column
            prop="appSoftName"
            align="center"
            :show-overflow-tooltip="true"
            label="应用软件"
          />

          <el-table-column
            prop="cmdName"
            align="center"
            :show-overflow-tooltip="true"
            label="指令 "
          />
          <el-table-column
            prop="appVersion"
            align="center"
            :show-overflow-tooltip="true"
            label="应用版本"
          />
          <el-table-column
            prop="adversion"
            align="center"
            :show-overflow-tooltip="true"
            label="轮播图版本"
          />
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            label="状态"
            prop="terminalTarget"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.terminalTarget.indexOf('在线') > -1"
                style="color: green"
                >{{ scope.row.terminalTarget }}</span
              >
              <span v-else style="color: red">{{
                scope.row.terminalTarget
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="140"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button-group>
                <el-button
                  v-permission="['PRIV_TERMINAL_UPDATE']"
                  type="info"
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                />
                <el-button
                  v-permission="['PRIV_TERMINAL_DELETE']"
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                />
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageIndex"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="batchFormOpen"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="batchFormCancel"
    >
      <el-form ref="batchForm" :model="batchForm" label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="暂收款时间"
              maxlength="255"
              prop="batchTempTime"
            >
              <el-time-select
                v-model="batchForm.tempTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:30',
                  end: '24:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitbatchForm"
          >确 定
        </el-button>
        <el-button @click="batchFormOpen = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="日志提取"
      :visible.sync="cmdOpen"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cmdOpen = false"
    >
      <el-form ref="cmdForm" :model="cmdForm" label-width="120px">
        <el-row>
          <el-col :span="12" v-show="cmdForm.command == '5'">
            <el-form-item
              label="提取日期"
              prop="uploadLogTime"
              :rules="
                cmdForm.command == '5'
                  ? cmdRules.uploadLogTime
                  : [{ required: false, trigger: 'blur' }]
              "
            >
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="cmdForm.uploadLogTime"
                style="width: 100%"
                type="date"
                placeholder="选择提取日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-show="cmdForm.command == '10'">
            <el-form-item
              label="提取参数 "
              maxlength="32"
              prop="cmdRef"
              :rules="
                cmdForm.command == '02'
                  ? cmdRules.cmdRef
                  : [{ required: false, trigger: 'blur' }]
              "
            >
              <el-input v-model="cmdForm.cmdRef" placeholder="请输入提取参数" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitCmdForm"
          >确 定
        </el-button>
        <el-button @click="cmdOpen = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编号 " maxlength="32" prop="terminalCode">
              <el-input
                v-model="form.terminalCode"
                placeholder="请输入设备编号 "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称 " maxlength="32" prop="terminalName">
              <el-input
                v-model="form.terminalName"
                placeholder="请输入设备名称 "
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="终端型号" prop="terminalModeID">
              <el-select
                v-model="form.terminalModeID"
                placeholder="请选择终端型号"
                style="width: 100%"
              >
                <el-option
                  v-for="item in terminalModeOptions"
                  :key="item.id"
                  clearable
                  :label="item.terminalModeCode + ' | ' + item.terminalModeName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属应用软件" prop="appID">
              <el-select
                v-model="form.appID"
                placeholder="请选择所属应用软件"
                style="width: 100%"
              >
                <el-option
                  v-for="item in appSoftOption"
                  :key="item.id"
                  clearable
                  :label="item.softName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属轮播图" prop="advID">
              <el-select
                v-model="form.advID"
                placeholder="请选择所属轮播图"
                style="width: 100%"
              >
                <el-option
                  v-for="item in advSoftOption"
                  :key="item.id"
                  clearable
                  :label="item.softCode + ' | ' + item.softName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="IP" maxlength="20" prop="ipAddr">
              <el-input v-model="form.ipAddr" placeholder="请输入IP" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="硬件编号 " maxlength="100" prop="hardWareCode">
              <el-input
                v-model="form.hardWareCode"
                placeholder="请输入硬件编号 "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="暂收款时间" maxlength="255" prop="tempTime">
              <el-time-select
                v-model="form.tempTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:30',
                  end: '24:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="监管终端号"
              maxlength="255"
              prop="superviseTerCode"
            >
              <el-select
                style="width: 100%"
                v-model="form.superviseTerCode"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择监管终端号"
              >
                <el-option
                  v-for="item in superviseTerCodeOptions"
                  :key="item.id"
                  :label="item.code+'|'+item.merchantName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                v-model="form.superviseTerCode"
                placeholder="请输入监管终端号"
              /> -->
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="监管商户号"
              maxlength="255"
              prop="superviseMerCode"
            >
              <el-select
                v-model="form.superviseMerCode"
                clearable 
                placeholder="请选择监管商户号"
                style="width: 100%"
              >
                <el-option
                  v-for="item in SuperviseMerchant"
                  :key="item.id"
                  clearable
                  :label="item.code + ' | ' + item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="银行终端号" maxlength="255" prop="bankTerCode">
              <el-select
                style="width: 100%"
                v-model="form.bankTerCode"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择非监管终端号"
              >
                <el-option
                  v-for="item in bankTerCodeOptions"
                  :key="item.id"
                  :label="item.code+'|'+item.merchantName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                v-model="form.bankTerCode"
                placeholder="请输入银行终端号"
              /> -->
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="银行商户号" maxlength="255" prop="bankMerCode">
                <el-input
                v-model="form.bankMerCode"
                placeholder="请输入银行商户号"
              />  
              <el-select
                v-model="form.bankMerCode"
                clearable 
                placeholder="请选择所属银行商户号"
                style="width: 100%"
              >
                <el-option
                  v-for="item in bankMerOption"
                  :key="item.id"
                  clearable
                  :label="item.code + ' | ' + item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="MAC地址" maxlength="255" prop="macAddress">
              <el-input v-model="form.macAddress" placeholder="请输入MAC地址" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否启用" prop="enable">
              <el-checkbox v-model="form.enable" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryTerminal,
  createTerminal,
  updateTerminal,
  deleteTerminal,
  updateCmdTerminal,
  batchUpdateTerminal,
} from "@/api/ter/terminal";
import {
  queryDeviceBankTerminal,
 
} from '@/api/ter/devicebankterminal'
import { getAllBankTerminal } from "@/api/ter/bankterminal";
import { getAllAdvSoft } from "@/api/ter/advsoft";
import { getAllAppSoft } from "@/api/ter/appsoft";
import { getAllorgan } from "@/api/system/organ";
import { getAllCmd } from "@/api/ter/cmd";
import { getAllTerminalMode } from "@/api/ter/terminalmode";
import { getAllMerchant } from "@/api/ter/merchant";
export default {
  name: "terminal",
  data() {
    return {
      // intervalIndex: -1,
      loadingRole: false,
      appSoftOption: [],
      advSoftOption: [],
      websock: null,
      txtReq: "",
      txtResp: "",
      cmdOpen: false,

      batchForm: { tempTime: "20:30" },
      cmdForm: {},
      // OrganOptions: [],
      cmdOptions: [],
      terminalModeOptions: [],
      superviseTerCodeOptions: [],
      bankTerCodeOptions: [],
      // 是否显示弹出层
      open: false,
      SuperviseMerchant: [],
      bankMerOption: [],
      batchFormOpen: false,
      // 表单
      form: { tempTime: "20:30" },
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
      },
      isOrgID: "",
      // 终端设备表 列表
      dataTerminal: [],
      //选中终端
      terminalSelections: [],
      // 表单校验
      cmdRules: {
        cmdRef: [
          { required: true, message: "文件提取参数不能为空", trigger: "blur" },
        ],
        uploadLogTime: [
          { required: true, message: "提取日期不能为空", trigger: "blur" },
        ],
      },
      rules: {
        terminalCode: [
          { required: true, message: "设备编号不能为空", trigger: "blur" },
        ],
        terminalName: [
          { required: true, message: "设备名称不能为空", trigger: "blur" },
        ],
        terminalModeID: [
          { required: true, message: "请选择终端型号", trigger: "blur" },
        ],
        macAddress: [
          { required: true, message: "MAC不能为空", trigger: "blur" },
        ],
        appID: [
          {
            required: true,
            message: "请选择所属应用软件",
            trigger: "blur",
          },
        ],
        advID: [
          {
            required: true,
            message: "请选择所属轮播图",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async created() {
    this.getList();
    // await this.getOrgan();
    getAllBankTerminal(true).then((response) => {
      this.superviseTerCodeOptions = response.data.filter(
        (item) => (item.isSupervise == 1)?true:false
      );
      this.bankTerCodeOptions = response.data.filter(
        (item) => (item.isSupervise == 0)?true:false
      );
    });
    getAllAdvSoft(true).then((response) => {
      this.advSoftOption = response.data;
    });
    getAllAppSoft(true).then((response) => {
      this.appSoftOption = response.data;
    });
    getAllTerminalMode(true).then((response) => {
      this.terminalModeOptions = response.data;
    });
    getAllCmd(true).then((response) => {
      this.cmdOptions = response.data;
    });
    getAllMerchant(true).then((response) => {
      this.SuperviseMerchant = response.data.filter((item) => {
        return item.isSupervise == 1;
      });

      this.bankMerOption = response.data.filter((item) => {
        return item.isSupervise != 1;
      });
    });

    // this.clearOnline();
    // this.initWebSocket();
  },
  methods: {
    clearOnline() {
      this.dataTerminal = this.dataTerminal.map((element) => {
        element.phoneNumber = "";
        element.terminalTarget = "";
        element.ipAddr = "";
        return element;
      });
    },
    /** 查询终端设备表 列表 */
    async getList() {
      //   this.queryParams.OrgID = this.isOrgID;
      this.loading = true;
      await queryTerminal(this.queryParams).then((response) => {
        this.dataTerminal = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },

    rowTableSelection(row) {
      if (row) {
        // this.isOrgID = row.id;
        this.getList();
      }
    },
    /* handleQuery() {
      this.getList();
    }, */
    /** 重置按钮操作 */
    /* handleReset() {
      this.queryParams = {
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
      };
      this.getList();
    }, */
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.form.tempTime = "20:30";
      this.open = true;
      this.title = "添加终端设备表 ";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();

      this.form = row;
        console.log('row.id==',row.id);
        queryDeviceBankTerminal({DeviceTeminalId:row.id}).then((response)=>{
          this.form.bankTerCode=response.data.filter(item=>item.isSupervise==0).map((item)=>{return item.bankTerminalId});
          this.form.superviseTerCode=response.data.filter(item=>item.isSupervise==1).map((item)=>{return item.bankTerminalId});
        })
      this.open = true;
      this.title = "修改终端设备表 ";
    },
    handleBatchUpdate() {
      //  this.reset();
      if (this.$refs.terminalTable.selection.length > 0) {
        this.batchFormOpen = true;
        this.title = "暂收款时间设置";
      } else {
        this.$message({
          message: "请至少选择一项",
          type: "warning",
        });
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.terminalName + '"的终端设备表 ?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteTerminal(row.terminalCode).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    submitbatchForm: function () {
      let _this = this;
      this.terminalSelections = [];
      this.$refs.terminalTable.selection.forEach((element) => {
        this.terminalSelections.push(element.id);
      });

      batchUpdateTerminal({
        TerminalIds: this.terminalSelections,
        tempTime: this.batchForm.tempTime,
      }).then((response) => {
        if (response.statusCode === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.batchFormOpen = false;

          _this.getList();
        }
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            updateTerminal(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          } else {
            createTerminal(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },

    submitCmdForm: function () {
      console.log(this.cmdForm.uploadLogTime);
      this.$refs["cmdForm"].validate((valid) => {
        if (valid) {
          this.ExecCmdTerminal(
            this.cmdForm.terIds,
            this.cmdForm.command,
            this.cmdForm.cmdRef,
            this.cmdForm.uploadLogTime
          );
        }
      });
    },

    // 显示搜索
    /* handleSearch() {
      this.searchToggle = !this.searchToggle;
    }, */
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        terminalName: undefined,
        terminalCode: undefined,
        orgID: undefined,
        terminalModeID: undefined,
        appSoftID: undefined,
        advSoftID: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    batchFormCancel() {
      this.batchFormOpen = false;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.$refs["form"].clearValidate();
      this.getList();
    },
    // 禁用用户
    handleCommand(command) {
      if (this.$refs.terminalTable.selection.length > 0) {
        this.terminalSelections = [];
        this.$refs.terminalTable.selection.forEach((element) => {
          this.terminalSelections.push(element.id);
        });
        if (command === "5" || command === "10") {
          this.cmdForm.terIds = this.terminalSelections;
          this.cmdForm.command = command;
          this.cmdOpen = true;
        } else {
          this.ExecCmdTerminal(
            this.terminalSelections,
            command,
            "",
            new Date()
          );
        }
      } else {
        this.$message({
          message: "请至少选择一项",
          type: "warning",
        });
      }
    },
    ExecCmdTerminal(terIds, command, cmdRef, logTime) {
      updateCmdTerminal({
        TerminalIds: terIds,
        DoCmd: command,
        CmdRef: cmdRef,
        UploadLogTime: logTime,
      }).then((response) => {
        if (response.statusCode === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.cmdOpen = false;
          this.getList();
        }
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
