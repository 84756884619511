import request from '@/utils/request'
export function queryorgan(data) {
  return request({
	url: 'organ/query',
	method: 'post',
	data
  })
}
export function getorgan(id) {
  return request({
	url: 'organ/get?id=' + id,
	method: 'get'
  })
}
export function getAllorgan(enable) {
  return request({
	url: 'organ/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createorgan(data) {
  return request({
	url: 'organ/create',
	method: 'post',
	data
  })
}
export function updateorgan(data) {
  return request({
	url: 'organ/update',
	method: 'post',
	data
  })
}
export function deleteorgan(id) {
  return request({
	url: 'organ/delete?id=' + id,
	method: 'get'
  })
}
export default { queryorgan, getorgan, getAllorgan, createorgan, updateorgan, deleteorgan }
