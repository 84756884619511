import request from '@/utils/request'
export function queryCmd(data) {
  return request({
	url: 'cmd/query',
	method: 'post',
	data
  })
}
export function getCmd(id) {
  return request({
	url: 'cmd/get?id=' + id,
	method: 'get'
  })
}
export function getAllCmd(enable) {
  return request({
	url: 'cmd/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createCmd(data) {
  return request({
	url: 'cmd/create',
	method: 'post',
	data
  })
}
export function updateCmd(data) {
  return request({
	url: 'cmd/update',
	method: 'post',
	data
  })
}
export function deleteCmd(id,cmdCode) {
  return request({
	url: 'cmd/delete?id=' + id+"&cmdCode="+cmdCode,
	method: 'get'
  })
}
export function enableCmds(data) {
	return request({
	  url: 'cmd/enable',
	  method: 'post',
	  data
	})
  }
export default { queryCmd, getCmd, getAllCmd, createCmd, updateCmd, deleteCmd ,enableCmds}
