import request from '@/utils/request'
export function queryUpLoad(data) {
  return request({
	url: 'upload/query',
	method: 'post',
	data
  })
}
export function getUpLoad(id) {
  return request({
	url: 'upload/get?id=' + id,
	method: 'get'
  })
}
export function getAllUpLoad(enable) {
  return request({
	url: 'upload/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createUpLoad(data) {
  return request({
	url: 'upload/create',
	method: 'post',
	data
  })
}
export function updateUpLoad(data) {
  return request({
	url: 'upload/update',
	method: 'post',
	data
  })
}
export function deleteUpLoad(id) {
  return request({
	url: 'upload/delete?id=' + id,
	method: 'get'
  })
}
export default { queryUpLoad, getUpLoad, getAllUpLoad, createUpLoad, updateUpLoad, deleteUpLoad }
