<template>
  <div class="login"></div>
</template>

<script>
import { getUserInfo } from "@/api/sunac/auth";
import defaultSettings from "@/settings";
import { getUrlKey } from "@/utils/auth";
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  name: "login",
  data() {
    return {
      title: defaultSettings.title,
      codeUrl: "",
      user: {
        username: "9999",
        password: "FC4509EFEA3AC97ED753F53DFA601161",
        code: "",
      },

      loading: false,
    };
  },
  created() {},
  mounted() {
    let code = getUrlKey("code"); //获取融创传递的Code
    let redirect_uri = window.location.origin + window.location.pathname;

    getUserInfo({ code, redirect_uri })
      .then((res) => {
        setToken(res.data);
        this.$store.commit("SET_TOKEN", res.data);
        // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
        this.$store.commit("SET_LOAD_MENUS", true);
        this.$router.push({ path: "/dashboard" });
      })
      .catch((res) => {
        alert(res.message);
      });
  },
  methods: {
    handleLogin() {
      this.loading = true;
      console.log(this.user, "================0======================");
      this.$store.commit("Login_sunac", this.user);
      // .then((response) => {
      //     console.log(this.user,'==============1========================');
      //   this.loading = false;
      //   if (response.statusCode === 200) {
      //    this.$router.push({ path: "/dashboard" });
      //   } else {
      //     this.$router.push({ path: "/" });
      //   }
      // })
      // .catch(() => {
      //   this.loading = false;
      //   this.$router.push({ path: "/" });
      // });
      //  console.log(this.user,'===============3=======================');
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #2d3a4b;
  background-size: cover;
}
.title {
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 385px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  display: inline-block;
  height: 38px;
}

.login-code img {
  cursor: pointer;
  vertical-align: middle;
}
</style>
