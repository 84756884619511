import request from '@/utils/request'
export async function  queryTerminal(data) {
  return await request({
	url: 'terminal/query',
	method: 'post',
	data
  })
}
export function getTerminal(id) {
  return request({
	url: 'terminal/get?id=' + id,
	method: 'get'
  })
}
export function getAllTerminal(enable) {
  return request({
	url: 'terminal/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createTerminal(data) {
  return request({
	url: 'terminal/create',
	method: 'post',
	data
  })
}
export function updateTerminal(data) {
  return request({
	url: 'terminal/update',
	method: 'post',
	data
  })
}

export function updateCmdTerminal(data) {
	return request({
	  url: 'terminal/UpdateCmdTerminal',
	  method: 'post',
	  data
	})
  }
  export function batchUpdateTerminal(data) {
	  console.log(data,'===================================================================');
	return request({
	  url: 'terminal/BatchUpdateTerminal',
	  method: 'post',
	  data
	})
  }
  
export function deleteTerminal(code) {
  return request({
	url: 'terminal/delete?code=' + code,
	method: 'get'
  })
}
export default { queryTerminal, getTerminal, getAllTerminal, createTerminal, updateTerminal, deleteTerminal }
