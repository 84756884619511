import request from '@/utils/request'
export function queryEstateOrgan(data) {
  return request({
	url: 'estateOrgan/query',
	method: 'post',
	data
  })
}
export function getEstateOrgan(id) {
  return request({
	url: 'estateOrgan/get?id=' + id,
	method: 'get'
  })
}
export function getAllEstateOrgan(enable) {
  return request({
	url: 'estateOrgan/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createEstateOrgan(data) {
  return request({
	url: 'estateOrgan/create',
	method: 'post',
	data
  })
}
export function updateEstateOrgan(data) {
  return request({
	url: 'estateOrgan/update',
	method: 'post',
	data
  })
}
export function deleteEstateOrgan(id) {
  return request({
	url: 'estateOrgan/delete?id=' + id,
	method: 'get'
  })
}
export default { queryEstateOrgan, getEstateOrgan, getAllEstateOrgan, createEstateOrgan, updateEstateOrgan, deleteEstateOrgan }
