
<template>
  <div class="app-container">
    <el-row v-if="searchToggle" :gutter="20">
      <el-col>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="支付方式编号">
            <el-input
              v-model="queryParams.code"
              placeholder="请输入支付方式编号"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item label="支付方式名称">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入支付方式名称"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="handleQuery"
              @clear="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="handleReset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" class="mb8">
      <el-button
        v-permission="['PRIV_PAYMODE_CREATE']"
        type="success"
        icon="el-icon-plus"
        size="mini"
        @click="handleCreate"
        >新增支付方式</el-button
      >
      <el-button-group style="margin-left: auto">
        <el-button
          size="mini"
          plain
          type="info"
          icon="el-icon-search"
          @click="handleSearch"
        />
        <el-button size="mini" icon="el-icon-refresh" @click="handleQuery" />
      </el-button-group>
    </el-row>
    <el-row>
      <el-table
        ref="paymode"
        v-loading="loading"
        :data="dataPayMode"
        row-key="id"
        stripe
        border
        :height="tableHeight * 0.65"
        :default-sort="{ prop: queryParams.orderby, order: queryParams.sort }"
        @sort-change="handleSortable"
      >
        <el-table-column
          type="index"
          :index="handleIndexCalc"
          label="#"
          align="center"
        />
        <el-table-column
          sortable
          prop="code"
          align="center"
          :show-overflow-tooltip="true"
          label="支付方式编号"
        />
        <el-table-column
          sortable
          prop="name"
          align="center"
          :show-overflow-tooltip="true"
          label="支付方式名称"
        />
        <el-table-column
          sortable
          prop="remark"
          align="center"
          :show-overflow-tooltip="true"
          label="备注"
        />
        <el-table-column sortable prop="enable" align="center" label="是否启用">
          <template slot-scope="scope">
            <i
              :style="scope.row.enable === true ? 'color:green' : 'color:red'"
              :class="
                scope.row.enable === true ? 'el-icon-success ' : 'el-icon-error'
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-permission="['PRIV_PAYMODE_UPDATE']"
                type="info"
                size="mini"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
              />
              <el-button
                v-permission="['PRIV_PAYMODE_DELETE']"
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
              />
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageIndex"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      @close="cancel"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="支付方式编号" maxlength="20" prop="code">
              <el-input v-model="form.code" placeholder="请输入支付方式编号" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="支付方式名称" maxlength="40" prop="name">
              <el-input v-model="form.name" placeholder="请输入支付方式名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" maxlength="255" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否启用" prop="enable">
              <el-checkbox v-model="form.enable" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick type="primary" @click="submitForm"
          >确 定
        </el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryPayMode,
  createPayMode,
  updatePayMode,
  deletePayMode,
} from "@/api/Estate/paymode";
export default {
  name: "paymode",
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单
      form: {},
      // 表单标题
      title: "",
      // 显示搜索
      searchToggle: true,
      // 表格高度
      tableHeight: window.innerHeight,
      // 合计条数
      total: 0,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        queryText: undefined,
        pageIndex: 1,
        pageSize: 10,
        orderby: "createTime",
        sort: "descending",
      },
      // 支付方式列表
      dataPayMode: [],
      // 表单校验
      rules: {
        code: [
          {
            required: true,
            message: "支付方式编号编号不能为空",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "支付方式名称编号不能为空",
            trigger: "blur",
          },
        ],
        enable: [
          { required: true, message: "是否启用编号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询支付方式列表 */
    getList() {
      this.loading = true;
      queryPayMode(this.queryParams).then((response) => {
        this.dataPayMode = response.data.dataSource;
        this.total = response.data.totalCount;
        this.loading = false;
      });
    },
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams.queryText = "";
      this.getList();
    },
    /** 新增按钮操作 */
    handleCreate() {
      this.reset();
      this.open = true;
      this.title = "添加支付方式";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.form = row;
      this.open = true;
      this.title = "修改支付方式";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.name + '"的支付方式?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deletePayMode(row.code).then((response) => {
            if (response.statusCode === 200) {
              this.getList();
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(function () {});
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined) {
            updatePayMode(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          } else {
            createPayMode(this.form).then((response) => {
              if (response.statusCode === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              }
            });
          }
        }
      });
    },
    // 显示搜索
    handleSearch() {
      this.searchToggle = !this.searchToggle;
    },
    // 排序操作
    handleSortable(val) {
      this.queryParams.orderby = val.prop;
      this.queryParams.sort = val.order;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        paymodeName: undefined,
        paymodeNo: undefined,
        createID: undefined,
        createName: undefined,
        createTime: undefined,
        enable: true,
        id: undefined,
        remark: undefined,
        updateID: undefined,
        updateName: undefined,
        updateTime: undefined,
      };
    },
    // 自动计算分页 Id
    handleIndexCalc(index) {
      return (
        (this.queryParams.pageIndex - 1) * this.queryParams.pageSize + index + 1
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.getList();
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
