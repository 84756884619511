import request from '@/utils/request'
export function queryMerchant(data) {
  return request({
	url: 'merchant/query',
	method: 'post',
	data
  })
}
export function getMerchant(id) {
  return request({
	url: 'merchant/get?id=' + id,
	method: 'get'
  })
}
export function getAllMerchant(enable) {
  return request({
	url: 'merchant/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createMerchant(data) {
  return request({
	url: 'merchant/create',
	method: 'post',
	data
  })
}
export function updateMerchant(data) {
  return request({
	url: 'merchant/update',
	method: 'post',
	data
  })
}
export function deleteMerchant(id) {
  return request({
	url: 'merchant/delete?id=' + id,
	method: 'get'
  })
}
export default { queryMerchant, getMerchant, getAllMerchant, createMerchant, updateMerchant, deleteMerchant }
