import request from '@/utils/request'
export function queryAppUpFile(data) {
  return request({
	url: 'appupfile/query',
	method: 'post',
	data
  })
}
export function getAppUpFile(id) {
  return request({
	url: 'appupfile/get?id=' + id,
	method: 'get'
  })
}
export function getAllAppUpFile(enable) {
  return request({
	url: 'appupfile/getAll' + (enable === undefined ? '' : ('?enable=' + enable)),
	method: 'get'
  })
}
export function createAppUpFile(data) {
  return request({
	url: 'appupfile/create',
	method: 'post',
	data
  })
}
export function updateAppUpFile(data) {
  return request({
	url: 'appupfile/update',
	method: 'post',
	data
  })
}
export function deleteAppUpFile(id) {
  return request({
	url: 'appupfile/delete?id=' + id,
	method: 'get'
  })
}
export default { queryAppUpFile, getAppUpFile, getAllAppUpFile, createAppUpFile, updateAppUpFile, deleteAppUpFile }
